
    @apply --marketplaceButtonStyles;

    background-color: var(--marketplaceColorSecond);
    color: var(--matterColorLight);

    &:hover,
    &:focus {
      background-color: var(--successColorDark);
    }

    &:disabled {
      background-color: var(--matterColorNegative);
      color: var(--matterColorLight);
    }
  